@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.imageContainer {
    position: absolute;
    height: 0;
    width: 0;
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
    object-fit: cover;
    inset: 0px;
}